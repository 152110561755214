<template>
  <div class="company-nav">
    <!-- TODO: Enable notifications element when it's ready -->
    <!-- <div class="company-nav__notifications">
      <i class="icon icon-notifications company-nav__notifications-image" />
      <p class="company-nav__notifications-count">
        {{ notifications }}
      </p>
    </div> -->
    <div
      v-click-outside="closeCompanyNavMenu"
      class="company-nav__menu"
    >
      <div
        class="company-nav__menu-trigger"
        data-testid="company-menu"
        @click="toggleCompanyNavMenu"
      >
        <i class="icon icon-payment-black company-nav__menu-trigger-icon" />
      </div>
      <div
        class="company-nav__menu-inner"
        :class="{ 'company-nav__menu-inner--active': companyNavOpened }"
      >
        <ul class="company-nav__menu-inner-list">
          <li
            v-for="route in routes"
            :key="route.name"
            class="company-nav__menu-inner-list-item"
          >
            <router-link
              :to="route.link"
              :data-testid="route.testId"
              active-class="company-nav__menu-inner-list-item-link--active"
              class="company-nav__menu-inner-list-item-link"
              @click.native.stop="closeCompanyNavMenu"
            >
              <i
                :class="[
                  'icon',
                  `icon-${route.icon}-black`,
                  'company-nav__menu-inner-list-item-link-image'
                ]"
              />
              <div
                v-t="route.name"
                class="company-nav__menu-inner-list-item-link-text"
              />
            </router-link>
          </li>
          <li class="company-nav__menu-inner-list-item">
            <div
              data-testid="company-menu-logout"
              class="company-nav__menu-inner-list-item-link"
              @click="logout"
            >
              <i class="icon icon-logout-black company-nav__menu-inner-list-item-link-image" />
              <div class="company-nav__menu-inner-list-item-link-text">
                {{ $t('partner-portal.header.company-routes.logout') }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesktopCompanyNav',

  props: {
    routes: {
      type: Array,
      required: true,
    },
    logout: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      companyNavOpened: false,
    };
  },

  computed: {
    notifications() {
      return 1;
    },
  },

  watch: {
    $route() {
      this.closeCompanyNavMenu();
    },
  },

  methods: {
    toggleCompanyNavMenu() {
      this.companyNavOpened = !this.companyNavOpened;
    },
    closeCompanyNavMenu() {
      this.companyNavOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/icons';
@import '~@/assets/scss/variables';
@import '~@/assets/scss/mixins';

$notification-color: #E31D1C;

.company-nav {
  display: none;
  align-items: center;

  &__notifications {
    display: flex;
    position: relative;
    margin-right: 28px;

    @include hover {
      cursor: pointer;
    }
  }

  &__notifications-image {
    width: 16px;
    height: 16px;
  }

  &__notifications-count {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 10px;
    width: 10px;
    background: $notification-color;
    color: $primary;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0;
  }

  &__menu {
    position: relative;
    display: flex;
    justify-self: center;
  }

  &__menu-trigger {
    height: 16px;
    width: 16px;

    @include hover {
      cursor: pointer;
    }
  }

  &__menu-inner {
    display: none;
    width: 240px;
    border-radius: 12px;
    padding: 8px;
    background: $primary;
    border: 1px solid $secondary;
    z-index: 1;

    &--active {
      display: flex;
      position: absolute;
      z-index: 1000;
      top: 30px;
      right: 0;
    }
  }

  &__menu-inner-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__menu-inner-list-item-link {
    box-sizing: border-box;
    padding: 12px;
    cursor: pointer;
    border-radius: 8px;
    text-decoration: none;
    color: $secondary;

    &--active,
    &:hover {
      background: $item-active-background;
    }
  }

  &__menu-inner-list-item,
  &__menu-inner-list-item-link {
    display: flex;
    width: 100%;
  }

  &__menu-inner-list-item-link-image {
    width: 16px;
    height: 16px;
  }

  &__menu-inner-list-item-link-text {
    display: flex;
    margin-left: 17px;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .company-nav {
    display: flex;
  }
}
</style>
